import React, { useState, useEffect } from "react";
import Gallery from "../components/Galery";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../utils/init";

function Landing() {
  const navigate = useNavigate();
  const [sessions, setSessions] = useState([]);

  const images = [
    { url: "/gallery-1.jpg" },
    { url: "/gallery-2.jpg" },
    { url: "/gallery-3.jpg" },
    { url: "/gallery-5.jpg" },
    { url: "/gallery-4.jpg" },
    { url: "/gallery-6.jpg" },
  ];

  const testimonialsData = [
    {
      text: "I wanted a website that truly represented my creative work and personality. Gaia Studio perfectly captured my vision and designed a website that is not only beautiful but also unique. They were a pleasure to work with, and I'm proud to showcase my portfolio on the site they created for me.",
      name: "Emily, USA",
      profilePic:
        "https://plus.unsplash.com/premium_photo-1681319553238-9860299dfb0f?auto=format&fit=crop&q=80&w=2831&ixlib=rb-4.0.3",
    },
    {
      text: "Gaia Studio completely transformed my small business with their innovative approach. The AI-powered design solutions they offered not only look amazing but are also highly effective in attracting customers. I'm thrilled with the results!",
      name: "Michael, USA",
      profilePic:
        "https://plus.unsplash.com/premium_photo-1681319553238-9860299dfb0f?auto=format&fit=crop&q=80&w=2831&ixlib=rb-4.0.3",
    },
    {
      text: "Gaia Studio has taken my e-commerce website to the next level. Their AI-driven design techniques have improved user experience and conversion rates. The team at Gaia Studio is a game-changer in the web design industry",
      name: "David, Canada",
      profilePic:
        "https://plus.unsplash.com/premium_photo-1681319553238-9860299dfb0f?auto=format&fit=crop&q=80&w=2831&ixlib=rb-4.0.3",
    },
  ];

  const load = async () => {
    const response = await fetchData(`/sessions/active`);

    const data = [];

    if (response.length > 3) {
      for (let index = 0; index < 3; index++) {
        data.push(response[index]);
      }

      setSessions(response ? data : []);
    } else {
      setSessions(response ? response : []);
    }
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <>
      <div className="hero min-h-screen bg-base-100">
        <div className="hero-content pb-20 flex justify-between max-sm:flex-col-reverse ">
          <div
            className="w-[50%] max-sm:w-full"
            data-aos-delay="400"
            data-aos="fade-right"
            data-aos-easing="linear"
          >
            <h1
              className="mb-5 text-6xl"
              style={{
                fontFamily: "Oleo Script",
              }}
            >
              Flow With <span className="">Gaia</span>,
              <span className="block mt-4">
                <span className="text-primary">Balance</span> Your Body
              </span>
            </h1>
            <p className="py-6 ">
              Flow your body, mind, and soul at{" "}
              <span className="font-semibold text-primary">Gaia Studio</span>.
              Prioritizing self-care is essential for your future health.
              Explore our classes for an amazing experience. Pilates is a great
              chance to enhance your strength, flexibility, and balance.
            </p>
            <button
              className="btn btn-primary rounded-full"
              onClick={() => {
                navigate("/sessions", { replace: true });
              }}
            >
              Join Our Latest Session
            </button>
          </div>
          <div
            data-aos-delay="400"
            data-aos="fade-left"
            data-aos-easing="linear"
            className="bg-white w-[40%] max-sm:w-full max-sm:my-20 min-h-[600px] rounded-t-full shadow-xl"
            style={{
              backgroundImage: "url(/background2.jpeg)",
              backgroundPositionX: "center",
              backgroundSize: "cover",
            }}
          ></div>
        </div>
      </div>

      <div className="place-items-center bg-primary w-full min-h-screen relative  flex flex-col gap-2">
        <div className="px-32 py-14 flex justify-center items-center flex-col w-[70%] max-sm:w-full max-sm:p-10 text-white">
          <div className="text-5xl text-center mb-10 font-thin">
            Transform your body with our profesional instructor
          </div>
          <div className="text-center">
            Discover a holistic approach to fitness that combines strength,
            flexibility, and mindfulness. Join our classes today and take the
            first step towards a healthier, more balanced you. Whether you're
            new to Pilates or looking to deepen your practice, our personalized
            sessions are designed to help you achieve your goals. Don’t
            wait—start your journey with us now and feel the difference!
          </div>
        </div>
        <div className="flex gap-2 w-full max-sm:flex-col">
          <div className="w-full min-h-[100vh] max-sm:min-h-fit relative">
            <div className="absolute top-[7%] -right-[35%] p-5 border-primary border-2 max-sm:static">
              <div
                data-aos-delay="400"
                data-aos="fade-right"
                data-aos-easing="linear"
                className="w-[400px] h-[500px] max-sm:w-full"
                style={{
                  backgroundImage: "url(/instructur.jpeg)",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              ></div>
            </div>
          </div>
          <div className="bg-white max-h-[80vh] p-5 min-w-[60%] max-sm:w-full max-sm:p-10 pl-44 py-20 pr-10 ">
            <div
              className="text-2xl font-semibold mb-10"
              data-aos-delay="500"
              data-aos="fade-left"
              data-aos-easing="linear"
            >
              Hi Im Royani Muksin,
            </div>
            <p
              className="w-[90%] mb-10 text-justify"
              data-aos-delay="600"
              data-aos="fade-left"
              data-aos-easing="linear"
            >
              A certified Pilates instructor with over a year of experience in
              helping clients achieve their fitness goals. My classes focus on
              building strength, improving flexibility, and enhancing overall
              well-being. Join me to transform your body and mind through
              tailored Pilates sessions designed to meet your unique needs.
            </p>

            <p
              className="w-[90%] text-justify"
              data-aos-delay="700"
              data-aos="fade-left"
              data-aos-easing="linear"
            >
              Ready to take your fitness journey to the next level? Join my
              Pilates classes at Gaia Studio, where you’ll find a supportive and
              encouraging environment. Whether you’re a beginner or experienced,
              I’m here to guide you through personalized sessions that will help
              you reach your goals. Let’s strengthen, stretch, and achieve
              balance together—sign up today and experience the transformative
              power of Pilates!
            </p>
          </div>
        </div>
      </div>

      {/* section */}
      <div className="grid place-items-center bg-base-100 w-full ">
        <div className="max-w-6xl w-full py-24 px-4 content-center justify-center">
          <h2 className="text-3xl  text-center font-bold mb-10">
            Galery Activity
          </h2>
          <Gallery images={images} />
        </div>
      </div>

      <div className="bg-base-100 place-items-center w-full">
        <h2 className="text-3xl  text-center font-bold mb-20">Session</h2>
        <div className="flex flex-col gap-32">
          {sessions.map((session, i) => {
            const isGenap = (i + 1) % 2;
            return (
              <div
                className="grid grid-cols-3 mx-20 gap-5 max-sm:flex max-sm:flex-col-reverse max-sm:mx-5"
                key={i}
                data-aos-delay={i == 0 ? "100" : `${100 + (i + 1) * 100}`}
                data-aos="fade-up"
                data-aos-easing="linear"
              >
                <div
                  className={`rounded-lg col-span-2 place-items-center flex justify-center
                    ${isGenap ? "order-1" : ""}`}
                >
                  <img
                    src={`${
                      process.env.REACT_APP_ENV === "production"
                        ? process.env.REACT_APP_BASE_URL_PROD
                        : process.env.REACT_APP_BASE_URL_SANDBOX
                    }/public/uploads/assets/${session?.photo}`}
                    className="object-cover w-[80%] h-[300px] rounded-lg max-sm:w-full"
                    alt="Not Found"
                  />
                </div>
                <div>
                  <h2 className="text-2xl font-semibold mb-10">
                    {session?.name}
                  </h2>
                  <div>{session?.description}</div>
                  <button className="btn btn-outline rounded-full w-[50%] mt-5">
                    Join
                  </button>
                </div>
              </div>
            );
          })}
        </div>
        <div className="pt-20 pb-10 flex justify-center">
          <button
            className="btn btn-primary w-fit"
            onClick={() => {
              navigate("/sessions");
            }}
          >
            More Sessions
          </button>
        </div>
      </div>

      {/* section */}
      {/* <div className="grid place-items-center w-full bg-base-100 ">
        <div className="max-w-6xl px-4 py-24 content-center justify-center">
          <h1 className="text-3xl  text-center font-bold">Testimonials</h1>
          <div className="grid mt-12 md:grid-cols-3 grid-cols-1 gap-8">
            {testimonialsData.map((t, k) => {
              return (
                <div key={k} className="card w-full bg-base-100 shadow-xl">
                  <figure className="px-10 pt-10">
                    <img
                      className="mask w-20 h-20 mask-circle object-cover"
                      src={t.profilePic}
                    />
                  </figure>
                  <div className="card-body items-center text-center">
                    <p>{t.text}</p>
                    <p className="text-slate-500">-{t.name}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div> */}

      <div
        className="grid place-items-center w-full min-h-screen"
        style={{
          background: "rgba(0, 0, 0, 0.50)",
        }}
      >
        <div className="flex justify-center flex-col items-center gap-10">
          <div className="text-white text-5xl w-[80%] text-center font-thin">
            CHOOSE YOUR SESSIONS AND START YOUR TRAINING
          </div>
          <button
            className="btn btn-primary rounded-full btn-lg"
            onClick={() => {
              navigate("/packages");
            }}
          >
            Book Now
          </button>
        </div>
      </div>
    </>
  );
}

export default Landing;
