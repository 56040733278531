import { useEffect, useState } from "react";
import PresentationChartBarIcon from "@heroicons/react/24/outline/PresentationChartBarIcon";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../utils/init";

function Sessions() {
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedIdProduct, setSelectedIdProduct] = useState([]);
  const [selectedDifficulty, setSelectedDifficulty] = useState([]);
  const [search, setSearch] = useState("");
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();

  const difficulty = ["opentoall", "beginner", "intermediette", "advance"];

  const load = async () => {
    try {
      setLoading(true);
      const responseProduct = await fetchData(`/products`);

      setProducts(
        responseProduct
          ? responseProduct.filter((item) => item.isProductActive === "true")
          : []
      );

      const response = await fetchData(`/sessions/active`);

      setSessions(response);
      setLoading(false);
    } catch (error) {
      // Handle errors here
      console.error("Error:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    load();
  }, []);

  if (loading) {
    return (
      <span className="loading loading-spinner w-[70px] mx-auto flex justify-center min-h-screen"></span>
    );
  }

  return (
    <>
      <div className="w-full bg-base-100 flex">
        <div className="min-w-[20%] max-sm:hidden min-h-screen border border-y-0 p-5">
          <label className="input input-bordered flex items-center gap-2">
            <input
              type="text"
              className="grow"
              placeholder="Search"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              className="w-4 h-4 opacity-70"
            >
              <path
                fillRule="evenodd"
                d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                clipRule="evenodd"
              />
            </svg>
          </label>
          {/* <div className="flex justify-end">
            <div className="dropdown mt-7">
              <div
                tabIndex={0}
                role="button"
                className="btn m-1 bg-primary text-white"
              >
                Level of difficulty
              </div>
              <ul
                tabIndex={0}
                className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
              >
                <li>
                  <a>Open To All</a>
                </li>
                <li>
                  <a>Beginner</a>
                </li>
                <li>
                  <a>Intermediette</a>
                </li>
                <li>
                  <a>Advance</a>
                </li>
              </ul>
            </div>
          </div> */}
          <div className="join join-vertical w-full mt-10">
            <div className="collapse collapse-arrow join-item border border-base-300">
              <input type="checkbox" name="my-accordion-4" defaultChecked />
              <div className="collapse-title text-xl font-medium">Product</div>
              <div className="collapse-content">
                <table className="table table-pin-rows">
                  <tbody>
                    {products.map((product) => {
                      return (
                        <tr key={product.id_product}>
                          <td>{product.name}</td>
                          <td>
                            <input
                              type="checkbox"
                              className="checkbox checkbox-primary"
                              defaultChecked={
                                selectedIdProduct.find(
                                  (item) => item === product.id_product
                                )
                                  ? true
                                  : false
                              }
                              onChange={() => {
                                if (
                                  selectedIdProduct.find(
                                    (item) => item === product.id_product
                                  )
                                ) {
                                  setSelectedIdProduct(
                                    selectedIdProduct.filter(
                                      (item) => item !== product.id_product
                                    )
                                  );
                                  // filter
                                } else {
                                  setSelectedIdProduct([
                                    ...selectedIdProduct,
                                    product.id_product,
                                  ]);
                                }
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="collapse collapse-arrow join-item border border-base-300">
              <input type="checkbox" name="my-accordion-4" />
              <div className="collapse-title text-xl font-medium">
                Level Of Difficulty
              </div>
              <div className="collapse-content">
                <table className="table table-pin-rows">
                  <tbody>
                    {difficulty.map((item) => {
                      return (
                        <tr>
                          <td className="capitalize">
                            {item === "opentoall" ? "Open To All" : item}
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              className="checkbox checkbox-primary"
                              onChange={() => {
                                if (
                                  selectedDifficulty.find((x) => x === item)
                                ) {
                                  setSelectedDifficulty(
                                    selectedDifficulty.filter((x) => x !== item)
                                  );
                                  // filter
                                } else {
                                  setSelectedDifficulty([
                                    ...selectedDifficulty,
                                    item,
                                  ]);
                                }
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full">
          <div
            className={`flex flex-col gap-9 rounded-lg mt-3 ${
              loading ? "" : ""
            }`}
          >
            {loading ? (
              <span className="loading loading-ring w-[70px] mx-auto flex justify-center min-h-screen"></span>
            ) : (
              <>
                {sessions
                  .filter((item) => {
                    if (
                      selectedIdProduct.length === 0 &&
                      selectedDifficulty.length === 0 &&
                      search.length === 0
                    ) {
                      return true;
                    }

                    let isThisItemValid = true;

                    if (search) {
                      if (
                        !item.name.toLowerCase().includes(search.toLowerCase())
                      ) {
                        isThisItemValid = false;
                      }
                    }

                    if (selectedIdProduct.length) {
                      if (
                        !selectedIdProduct.find((id) => id === item.id_product)
                      ) {
                        isThisItemValid = false;
                      }
                    }

                    if (selectedDifficulty.length) {
                      if (
                        !selectedDifficulty.find((id) => id === item.difficulty)
                      ) {
                        isThisItemValid = false;
                      }
                    }

                    return isThisItemValid;
                  })
                  .map((session, index) => {
                    // console.log(session);
                    return (
                      <div
                        className={`w-full flex px-6 max-sm:flex-col ${
                          index % 2 == 0 ? "flex-row-reverse " : ""
                        }`}
                      >
                        <h2 className="text-2xl font-semibold mb-10 md:hidden">
                          {session.name}
                        </h2>
                        <div className="min-w-[50%] m-auto relative">
                          <div
                            className="w-full max-h-[350px] absolute top-0"
                            style={{
                              zIndex: 2,
                              background: "rgba(0, 0, 0, 0.35)",
                            }}
                          ></div>
                          <img
                            src={`${
                              process.env.REACT_APP_ENV === "production"
                                ? process.env.REACT_APP_BASE_URL_PROD
                                : process.env.REACT_APP_BASE_URL_SANDBOX
                            }/public/uploads/assets/${session?.photo}`}
                            className="object-cover w-full max-h-[350px] "
                            alt="Not Found"
                          />
                        </div>
                        <div className="p-5 flex flex-col justify-between w-full">
                          <div>
                            <h2 className="text-2xl font-semibold mb-10 max-sm:hidden">
                              {session.name}
                            </h2>
                            <div>{session.description}</div>
                            <div className="flex items-center gap-2 my-5">
                              <PresentationChartBarIcon className="w-5" />
                              <p className="font-semibold text-sm capitalize">
                                {session.difficulty === "opentoall"
                                  ? "Open To All"
                                  : session.difficulty}
                              </p>
                            </div>
                          </div>
                          <div className="mt-5 grid grid-cols-2 gap-3">
                            <div className="card-actions">
                              <button
                                className="btn btn-primary  w-full"
                                onClick={() =>
                                  navigate(`/sessions/${session.id_session}`, {
                                    replace: false,
                                  })
                                }
                              >
                                Join
                              </button>
                            </div>
                            <div className="card-actions">
                              <button
                                className="btn btn-outline   w-full"
                                onClick={() =>
                                  navigate(
                                    `/sessions/schedules/${session.id_session}`,
                                    {
                                      replace: false,
                                    }
                                  )
                                }
                              >
                                See Schedule
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Sessions;
