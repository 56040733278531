import React, { useRef, useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import ChevronLeftIcon from "@heroicons/react/24/outline/ChevronLeftIcon";
import ChevronRightIcon from "@heroicons/react/24/outline/ChevronRightIcon";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import dayjs from "dayjs";
import CircleStackIcon from "@heroicons/react/24/outline/CircleStackIcon";
import ClockIcon from "@heroicons/react/24/outline/ClockIcon";
import "./index.css";
import { fetchData, postData } from "../utils/init";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { MODAL_BODY_TYPES } from "../utils/globalConstantUtil";
import { openModal } from "../store/modalSlice";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useSwipeable } from "react-swipeable";

function CalendarView({ schedules, sessions, setSchedules }) {
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
  const location = useLocation();
  const dispatch = useDispatch();
  const calendarRef = useRef();
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const [viewTitle, setViewTitle] = useState("");
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [filterSession, setFilterSession] = useState(
    sessions.map((item) => item.id_session)
  );
  const [selectedDateMobile, setSelectedDateMobile] = useState(new Date());

  window.mobilecheck = function () {
    var check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };

  const [isSwiping, setIsSwiping] = useState(false);
  const [direction, setDirection] = useState("");

  const [loading, setloading] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [userSubscription, setUserSubscription] = useState([]);
  const [view, setView] = useState(
    window.mobilecheck() ? "timeGridDay" : "timeGridWeek"
  );

  const query = new URLSearchParams(useLocation().search);
  const paramValue = query.get("id_schedule");
  const [startedDate, setStartedDate] = useState(new Date());

  const listWeekDate = (startDate) => {
    let weekDates = [];
    let currentDate = new Date(startDate);

    for (let i = 0; i < 7; i++) {
      weekDates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return weekDates;
  };

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      setIsSwiping(false);
      setDirection("");
      const list = listWeekDate(startedDate);
      let newList = [];
      if (eventData.dir === "Left") {
        const endDate = list[list.length - 1];
        endDate.setDate(endDate.getDate() + 1);
        setStartedDate(endDate);
        newList = listWeekDate(endDate);
      } else if (eventData.dir === "Right") {
        const startDate = list[0];
        startDate.setDate(startDate.getDate() - 7);
        setStartedDate(startDate);
        newList = listWeekDate(startDate);
      }
      setSelectedDate(
        dayjs(
          newList[
            selectedDateMobile.getDay() === 0
              ? 6
              : selectedDateMobile.getDay() - 1
          ]
        )
      );

      setSelectedDateMobile(
        newList[
          selectedDateMobile.getDay() === 0
            ? 6
            : selectedDateMobile.getDay() - 1
        ]
      );
    },
    onSwiping: (eventData) => {
      setIsSwiping(true);
      setDirection(eventData.dir);
    },
  });

  const handlersCalenderSwipe = useSwipeable({
    onSwiped: (eventData) => {
      setIsSwiping(false);
      setDirection("");
      const date = new Date(selectedDateMobile);
      if (eventData.dir === "Right") {
        date.setDate(date.getDate() - 1);
        setSelectedDateMobile(date);
        setSelectedDate(dayjs(date));
        if (date.getDay() === 0) {
          const startDate = date;
          startDate.setDate(startDate.getDate() - 6);
          setStartedDate(startDate);
        }
      } else if (eventData.dir === "Left") {
        date.setDate(date.getDate() + 1);
        setSelectedDateMobile(date);
        setSelectedDate(dayjs(date));
        if (date.getDay() === 1) {
          setStartedDate(date);
        }
      }
    },
    onSwiping: (eventData) => {
      setIsSwiping(true);
      setDirection(eventData.dir);
    },
  });

  const loadSubscriptionUser = async () => {
    let data = await fetchData(`/subscriptions/member/${user.username}`);
    const today = dayjs();

    data = data.filter((item) => {
      console.log(item);
      const startDate = dayjs(item.start_subscription);
      const endDate = dayjs(item.end_subscription);
      const isTodayInRange =
        today.isAfter(startDate) && today.isBefore(endDate);

      if (item.active_claim >= item.times || !isTodayInRange) {
        return false;
      }

      if (selectedSchedule) {
        if (item.id_product === selectedSchedule.id_product) {
          return true;
        } else {
          return false;
        }
      }

      if (item.status !== "active") {
        return false;
      }

      return true;
    });
    setUserSubscription(data);
  };

  useEffect(() => {
    if (calendarRef.current) {
      // viewNameSet("day", selectedDate);
      setView("timeGridDay");
      let calendarApi = calendarRef.current.getApi();
      calendarApi.changeView("timeGridDay");
      calendarApi.gotoDate(selectedDateMobile);

      setViewTitle(calendarApi?.view?.title);
    }
  }, [selectedDateMobile]);

  useEffect(() => {
    if (paramValue) {
      const findSchedule = schedules.find(
        (item) => item.id_schedule === paramValue
      );
      loadSubscriptionUser();
      if (findSchedule) {
        setShowDrawer(true);
        setSelectedSchedule(findSchedule);
      }
    }
  }, [paramValue]);

  useEffect(() => {
    if (localStorage.getItem("user")) {
      loadSubscriptionUser();
    }
  }, [selectedSchedule]);

  function addMinutesToDate(dateString, minutes) {
    // Create a new Date object from the given date string
    let date = new Date(dateString);

    // Add the specified number of minutes to the date
    date.setMinutes(date.getMinutes() + minutes);

    return date.toISOString();
  }

  const viewOptions = [
    {
      label: "Time Week",
      value: "timeGridWeek",
    },
    {
      label: "Day",
      value: "timeGridDay",
    },
  ];

  function renderEventContent(eventInfo) {
    const data = eventInfo.event.extendedProps;
    // console.log(eventInfo.event, "color");

    return (
      <label
        htmlFor="my-drawer-4"
        className="m-0 w-full p-1 pt-0 h-full text-white relative flex justify-between flex-col drawer-button cursor-pointer"
        style={{
          background: eventInfo.event.extendedProps.color,
        }}
      >
        <div>
          <div>{eventInfo.timeText}</div>
          <div className="text-xs">{eventInfo.event.title}</div>
        </div>
      </label>
    );
  }

  const handleNavigationCalendar = (type) => {
    let calendarApi = calendarRef.current.getApi();

    if (type === "next") {
      calendarApi.next();
    }

    if (type === "prev") {
      calendarApi.prev();
    }

    if (type === "today") {
      calendarApi.today();
    }

    var date = calendarApi.getDate();
    setViewTitle(calendarApi?.view?.title);
    setSelectedDate(dayjs(date));

    // setCurrentMonth(date.getMonth())
    // setCurrentYear(date.getFullYear())

    // const currentMonth = itemsMonthsOptions.find(
    //   (month) =>
    //     month.label === `${months[date.getMonth()]} ${date.getFullYear()}`
    // )
    // setValueCalendarMonthsSelections(currentMonth)
  };

  useEffect(() => {
    if (calendarRef.current) {
      let calendarApi = calendarRef.current.getApi();
      setViewTitle(calendarApi?.view?.title);
    }
  }, [view]);

  const load = async (start, end) => {
    try {
      // setLoading(true);
      const response = await postData(`/schedules/filter`, {
        start: start,
        end: end,
      });

      // console.log(response);

      setSchedules(response ? response : []);
      // setLoading(false);
    } catch (error) {
      // Handle errors here
      console.error("Error:", error);
      // setLoading(false);
    }
  };

  function getWeekDate(date) {
    const inputDate = new Date(date);
    const day = inputDate.getDay(); // 0 (Sunday) to 6 (Saturday)

    // Calculate difference to Sunday
    const diffToSunday = -day;
    // Calculate difference to Saturday
    const diffToSaturday = 6 - day;

    const sunday = new Date(inputDate);
    sunday.setDate(inputDate.getDate() + diffToSunday);

    const saturday = new Date(inputDate);
    saturday.setDate(inputDate.getDate() + diffToSaturday);

    return {
      start: dayjs(sunday).format("YYYY-MM-DDTHH:mm:ss"),
      end: dayjs(saturday).format("YYYY-MM-DDTHH:mm:ss"),
    };
  }

  useEffect(() => {
    if (selectedDate) {
      const { start, end } = getWeekDate(selectedDate);
      load(start, end);
    }
  }, [selectedDate]);

  return (
    <div className="">
      <div className="drawer drawer-end z-[999]">
        <input
          id="my-drawer-4"
          type="checkbox"
          className="drawer-toggle"
          checked={showDrawer}
        />
        <div className="drawer-content"></div>
        <div className="drawer-side max-sm:z-[49]">
          <label
            htmlFor="my-drawer-4"
            aria-label="close sidebar"
            className="drawer-overlay"
            onClick={() => {
              setShowDrawer(false);
            }}
          ></label>
          <div className="bg-white min-h-full w-80 p-5">
            {selectedSchedule ? (
              <>
                <div className="">
                  <label
                    htmlFor="my-drawer-4"
                    className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
                    onClick={() => {
                      setShowDrawer(false);
                    }}
                  >
                    ✕
                  </label>
                  <h3 className="font-bold text-lg border-2 border-x-0 border-t-0 border-solid pb-5">
                    {
                      sessions.find(
                        (item) =>
                          item.id_session === selectedSchedule.id_session
                      )?.name
                    }{" "}
                    Schedule
                  </h3>

                  <div className="mb-10 mt-7">
                    <div className="font-bold text-xl mb-5">
                      {selectedSchedule?.date
                        ? dayjs(selectedSchedule.date).format("dddd LL ")
                        : null}
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2">
                      <div className="flex justify-between items-center">
                        <div className="flex items-center gap-3 text-md font-semibold">
                          <ClockIcon className="w-5" />
                          {dayjs(selectedSchedule.date).format("HH:mm")},
                          <span className="">
                            {selectedSchedule?.duration} Mins
                          </span>
                        </div>
                      </div>
                      <div>
                        <div className="flex justify-between items-center">
                          <div className="flex items-center gap-3 text-md font-semibold">
                            <CircleStackIcon className="w-5" />
                            {selectedSchedule.current_quota
                              ? selectedSchedule.max_quota -
                                selectedSchedule.current_quota
                              : selectedSchedule.max_quota}{" "}
                            / {selectedSchedule.max_quota}{" "}
                            {selectedSchedule.max_quota ===
                            selectedSchedule.current_quota
                              ? "(FULL)"
                              : "Left"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {user ? (
                    <div className="mt-3 mb-6">
                      <div className="mb-2 font-semibold text-md">
                        Choose Subscription
                      </div>
                      {userSubscription.length === 0 ? (
                        <>
                          You have not subscribe to this{" "}
                          <Link
                            to={`/packages?id_product=${selectedSchedule.id_product}`}
                            className="font-semibold underline cursor-pointer text-primary"
                          >
                            package
                          </Link>
                        </>
                      ) : (
                        <div className="flex flex-col">
                          {userSubscription.map((subs, i) => {
                            return (
                              <div className="form-control" key={i}>
                                <label className="label cursor-pointer">
                                  <span className="label-text w-full mr-4">
                                    <div>{subs.nama_paket}</div>
                                    <div className="flex justify-between items-center w-full font-semibold text-xs">
                                      <div>
                                        {subs.subscription_group_ids
                                          ? "Group"
                                          : "Single"}
                                      </div>
                                      <div>
                                        {subs.active_claim}/{subs.times} Claims
                                      </div>
                                    </div>
                                  </span>
                                  <input
                                    type="radio"
                                    name="radio-10"
                                    className="radio radio-primary"
                                    onClick={(e) => {
                                      setSelectedSubscription(
                                        subs.id_subscription
                                      );
                                    }}
                                  />
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className="grid grid-cols-2 gap-2">
                    <label
                      htmlFor="my-drawer-4"
                      className={`btn btn-secondary w-full text-white ${
                        selectedSchedule.max_quota ===
                        selectedSchedule.current_quota
                          ? "col-span-2"
                          : ""
                      }`}
                      onClick={() => {
                        setShowDrawer(false);
                      }}
                    >
                      Close
                    </label>
                    {selectedSchedule.max_quota ===
                    selectedSchedule.current_quota ? (
                      <></>
                    ) : (
                      <button
                        className={`btn btn-primary ${
                          loading ? "btn-disabled" : ""
                        }`}
                        onClick={async () => {
                          if (!user) {
                            setSelectedSubscription(null);
                            localStorage.setItem(
                              "redirectPath",
                              `${location.pathname}?id_schedule=${selectedSchedule.id_schedule}`
                            );

                            dispatch(
                              openModal({
                                title: "",
                                size: "lg",
                                bodyType: MODAL_BODY_TYPES.SIGN_IN_MODAL,
                                extraObject: { isSignIn: true },
                              })
                            );

                            return;
                          }

                          if (!selectedSubscription) {
                            toast.error("Choose your subscription", {
                              position: "top-right",
                              autoClose: 5000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                            });

                            return;
                          }

                          setloading(true);

                          const res = await postData("/active-session", {
                            id_attendance: crypto.randomUUID(),
                            id_member: user.username,
                            id_session: selectedSchedule.id_session,
                            id_schedule: selectedSchedule.id_schedule,
                            id_subscription: selectedSubscription,
                            status: "active",
                          });

                          if (res?.data?.message) {
                            // console.log(res?.data?.message, "message");
                            toast.error(res?.data?.message, {
                              position: "top-right",
                              autoClose: 5000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                            });

                            setloading(false);
                          } else {
                            toast.success("Success joining session", {
                              position: "top-right",
                              autoClose: 5000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                            });

                            setloading(false);
                            setShowDrawer(false);
                            setSelectedSubscription(null);
                            setSelectedSchedule(null);
                            loadSubscriptionUser();
                            const { start, end } = getWeekDate(selectedDate);
                            load(start, end);
                          }
                        }}
                      >
                        {loading ? (
                          <span className="loading loading-spinner loading-lg text-primary"></span>
                        ) : (
                          "Join"
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      <div className="flex gap-10 max-sm:flex-col-reverse">
        <div className="pb-9 px-0 flex flex-col items-start max-sm:items-center gap-10">
          <div className="px-0">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateCalendar
                value={selectedDate}
                onChange={(newValue) => {
                  let calendarApi = calendarRef.current.getApi();
                  calendarApi.gotoDate(newValue.toISOString());
                  setViewTitle(calendarApi?.view?.title);
                  setSelectedDate(newValue);
                  setSelectedDateMobile(new Date(newValue));
                }}
                className="border border-solid rounded-lg shadow-md bg-white rounded-e-lg "
                sx={{
                  "& .Mui-selected": {
                    backgroundColor: "#D28F89", // Override default background color
                    "&.Mui-selected": {
                      backgroundColor: "#D28F89", // Ensure it's fully overridden
                    },
                    "&.MuiPickersDay-day": {
                      backgroundColor: "transparent", // Ensure it's fully overridden
                    },
                    "&:hover": {
                      backgroundColor: "transparent", // Override hover background color
                    },
                  },
                  "& .MuiPickersDay-root.Mui-selected": {
                    backgroundColor: "#D28F89", // Override default background color
                    "&.Mui-selected": {
                      backgroundColor: "#D28F89", // Ensure it's fully overridden
                    },
                  },
                }}
              />
            </LocalizationProvider>
          </div>

          <div className=" w-full bg-white border border-solid rounded-lg shadow-md p-5 px-7">
            <div className="text-lg font-bold mb-5">Sessions</div>
            <div className="flex flex-col gap-3">
              {/* {sessions.map((session) => {
                return (
                  <>
                    <div className="font-semibold border-t-0 border-x-0 border-b pb-2 border-black">
                      {session.nama}
                    </div>
                    <>
                      {session.items.map((item) => {
                        const isChecked = filterSession.includes(
                          item.id_session
                        );
                        return (
                          <div className="form-control">
                            <label className="cursor-pointer flex items-center gap-5 ">
                              <input
                                type="checkbox"
                                defaultChecked={isChecked}
                                className={`checkbox checkbox-primary checkbox-lg [--chkfg:white] `}
                                onChange={() => {
                                  let filter = [...filterSession];
                                  if (isChecked) {
                                    filter = filterSession.filter(
                                      (fil) => fil != item.id_session
                                    );
                                  } else {
                                    filter = [...filter, item.id_session];
                                  }

                                  setFilterSession(filter);
                                }}
                              />
                              <span
                                className="text-md font-semibold flex gap-2 items-center"
                                style={{
                                  color: item.color,
                                }}
                              >
                                <div
                                  className="w-3 h-3 "
                                  style={{
                                    background: item.color,
                                  }}
                                ></div>
                                <div>{item.name}</div>
                              </span>
                            </label>
                          </div>
                        );
                      })}
                    </>
                  </>
                );
              })} */}

              {sessions.map((item) => {
                const isChecked = filterSession.includes(item.id_session);
                return (
                  <div className="form-control">
                    <label className="cursor-pointer flex items-center gap-5 ">
                      <input
                        type="checkbox"
                        defaultChecked={isChecked}
                        className={`checkbox checkbox-primary checkbox-lg [--chkfg:white] `}
                        onChange={() => {
                          let filter = [...filterSession];
                          if (isChecked) {
                            filter = filterSession.filter(
                              (fil) => fil != item.id_session
                            );
                          } else {
                            filter = [...filter, item.id_session];
                          }

                          setFilterSession(filter);
                        }}
                      />
                      <span
                        className="text-md font-semibold flex gap-2 items-center"
                        style={{
                          color: item.color,
                        }}
                      >
                        <div
                          className="w-3 h-3 "
                          style={{
                            background: item.color,
                          }}
                        ></div>
                        <div>{item.name}</div>
                      </span>
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="w-full max-sm:flex max-sm:flex-col-reverse max-sm:gap-5 ">
          <div className="w-full flex justify-end max-sm:justify-center max-sm:hidden">
            <div className="max-sm:flex-col max-sm:gap-3 relative flex items-center justify-between w-[95%]">
              <div className="flex items-center gap-3 justify-between max-sm:flex-col-reverse max-sm:w-full ">
                <div className="flex items-center max-sm:justify-evenly gap-5">
                  <button
                    className={
                      "px-2 btn btn-sm max-sm:btn-md max-sm:flex-1 btn-outline "
                    }
                    onClick={() => handleNavigationCalendar("prev")}
                  >
                    <ChevronLeftIcon className="w-6" />
                  </button>
                  <button
                    onClick={() => handleNavigationCalendar("today")}
                    className="btn btn-sm max-sm:btn-md max-sm:flex-1 btn-outline  px-6"
                  >
                    Today
                  </button>
                  <button
                    className={
                      "px-2 btn btn-sm max-sm:btn-md max-sm:flex-1 btn-outline "
                    }
                    onClick={() => handleNavigationCalendar("next")}
                  >
                    <ChevronRightIcon className="w-6" />
                  </button>
                </div>
                <div>
                  <div className="text-xl font-semibold leading-none ml-5">
                    {viewTitle}
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-3 max-sm:w-full  ">
                <div className="dropdown dropdown-end max-sm:hidden ">
                  <div
                    tabIndex={0}
                    role="button"
                    className="btn btn-sm btn-primary m-1 max-sm:w-full "
                  >
                    {view == "timeGridWeek" ? "Week" : "Day"}
                  </div>
                  <ul
                    tabIndex={0}
                    className="dropdown-content z-[1] menu p-2 shadow bg-white rounded-box w-52 max-sm:w-full"
                  >
                    {viewOptions.map((item) => {
                      return (
                        <li key={item.value}>
                          <button
                            className=""
                            onClick={() => {
                              setView(item.value);
                              let calendarApi = calendarRef.current.getApi();
                              calendarApi.changeView(item.value);
                            }}
                          >
                            {item.label}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="md:hidden w-full fixed top-[64px] left-0 right-0 z-[48] bg-white p-7">
            <div
              className={`grid grid-cols-7 gap-5 place-items-center swipe-container ${
                isSwiping ? `swiping.${direction}` : ""
              }`}
              {...handlers}
            >
              {listWeekDate(startedDate).map((item) => {
                const isToday =
                  dayjs(item).format("DD") ===
                  dayjs(selectedDateMobile).format("DD");

                return (
                  <div>
                    <div className="text-center mb-3">
                      {dayjs(item).format("dd")}
                    </div>
                    <div>
                      <button
                        className={`btn btn-sm ${
                          isToday ? "btn-primary text-white" : ""
                        }  `}
                        onClick={() => {
                          setSelectedDateMobile(item);
                          setSelectedDate(dayjs(item));
                          let calendarApi = calendarRef.current.getApi();
                          calendarApi.gotoDate(item);
                        }}
                      >
                        {dayjs(item).format("DD")}
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="text-center mt-4">
              {dayjs(selectedDateMobile).format("dddd D MMMM YYYY")}
            </div>
          </div>

          <div className="max-sm:mt-32" {...handlersCalenderSwipe}>
            <FullCalendar
              viewClassNames={""}
              ref={calendarRef}
              plugins={[
                momentTimezonePlugin,
                dayGridPlugin,
                interactionPlugin,
                timeGridPlugin,
              ]}
              height={600}
              progressiveEventRendering
              initialView={view}
              slotLabelFormat={{
                hour: "numeric",
                minute: "2-digit",
                hour12: false, // Use 24-hour format
              }}
              slotMinTime="08:00:00" // Start time for the calendar
              slotMaxTime="21:00:00" // End time for the calendar
              slotDuration="00:30:00"
              allDaySlot={false}
              events={schedules
                .filter((item) => {
                  return filterSession.includes(item.id_session);
                })
                .map((item) => {
                  const session = sessions.find(
                    (ses) => item.id_session === ses.id_session
                  );

                  return {
                    id: item.id_schedule,
                    title: session ? session.name : "Event",
                    start: item.date,
                    end: addMinutesToDate(item.date, parseInt(item.duration)),
                    duration_event: item.duration,
                    current_quota: item.current_quota,
                    max_quota: item.max_quota,
                    data: item,
                    color: session.color,
                  };
                })}
              eventContent={renderEventContent}
              eventClick={(e) => {
                setShowDrawer(true);
                setSelectedSchedule(e.event.extendedProps.data);
              }}
              headerToolbar={{
                left: "",
                center: "",
                right: "",
              }}
              eventClassNames={
                "cursor-pointer bg-primary border-primary p-0 w-full m-0 relative drawer-button"
              }
              dayHeaderContent={({ date, isToday }) => {
                if (view == "dayGridMonth") {
                  return <></>;
                } else {
                  const isDateBeforeTen = date.getDate() < 10;
                  const dateString = `${
                    isDateBeforeTen ? "0" : ""
                  }${date.getDate()}`;
                  return (
                    <div className={"sticky flex w-full flex-col px-2 py-1"}>
                      <span className="text-md font-normal">
                        {`${daysOfWeek[date.getDay()]}`}
                      </span>
                      <span
                        onClick={() => {
                          setSelectedDate(dayjs(date));
                          setView("timeGridDay");
                          let calendarApi = calendarRef.current.getApi();
                          calendarApi.gotoDate(date.toISOString());
                          calendarApi.changeView("timeGridDay");
                          setViewTitle(calendarApi?.view?.title);
                        }}
                        className={`text-3xl max-sm:text-xl font-normal rounded-full hover:bg-gray-200 cursor-pointer p-2 ho ${
                          isToday ? "bg-primary text-white" : ""
                        }`}
                      >
                        {dateString}
                      </span>
                    </div>
                  );
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CalendarView;
