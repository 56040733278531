import { useEffect, useState } from "react";
import CheckBadgeIcon from "@heroicons/react/24/outline/CheckBadgeIcon";
import ClockIcon from "@heroicons/react/24/outline/ClockIcon";
import { fetchData, postData } from "../utils/init";
import { useLocation, useNavigate } from "react-router-dom";
import { MODAL_BODY_TYPES } from "../utils/globalConstantUtil";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import PhoneIcon from "@heroicons/react/24/outline/PhoneIcon";
import InboxIcon from "@heroicons/react/24/outline/InboxIcon";
import UserIcon from "@heroicons/react/24/outline/UserIcon";
import { openModal as openModalLogin } from "../store/modalSlice";

function PackagesPage() {
  const [packages, setPackages] = useState([]);
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedIdProduct, setSelectedIdProduct] = useState("semua");
  const [selectedPackage, setSelectedPackage] = useState("");
  const [formPackage, setFormPackage] = useState([
    {
      username: user?.username,
      phone_num: user?.phone_num,
      email: user?.email,
    },
  ]);
  const location = useLocation();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [showManualJoin, setShowManualJoin] = useState(false);
  const navigate = useNavigate();

  const [file, setFile] = useState(null);

  const handleFile = (newFile) => {
    setFile(newFile); // Replace the file
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles.length > 0) {
      handleFile(droppedFiles[0]); // Take only the first file
    }
  };

  const preventDefaults = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleRemove = () => {
    setFile(null); // Remove the file
  };

  async function uploadFile(dataFile) {
    let formData = new FormData();
    // formData.append("filename", dataFile.name);
    formData.append("uploaded_file", dataFile);

    var resp = await fetch(
      `${
        process.env.REACT_APP_ENV === "production"
          ? process.env.REACT_APP_BASE_URL_PROD_API
          : process.env.REACT_APP_BASE_URL_SANDBOX_API
      }/upload-file-bukti-bayar`,
      {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    var json = await resp.json();
    if (json.message === "Sukses") {
      return json.datasend;
    }

    return "";
  }

  function handleFileUpload() {
    if (file) {
      return uploadFile(file);
    }

    return;
  }

  async function handleSavePembayaranManual() {
    if (!file) {
      toast.error("Mohon untuk melakukan upload bukti bayar", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
      return;
    }

    // joinPackage(selectedPackage);
    const imageName = await handleFileUpload();
    let currentUser = JSON.parse(localStorage.getItem("user"));
    let picUsername;
    let id_subs = crypto.randomUUID();

    if (selectedPackage.pax > 1) {
      if (selectedPackage.pax == formPackage.length) {
        picUsername = formPackage[0];
        await postData("/subscriptions/groups/user", {
          groups: formPackage,
          id_subscription: id_subs,
        });
      }
    }

    let currentDate = new Date(); // Current date
    let futureDate = new Date();
    futureDate.setDate(futureDate.getDate() + selectedPackage.valid_days);

    let newSubscriptionObj = {
      id_packages: selectedPackage.id_packages,
      username: picUsername ? picUsername.username : currentUser.username,
      id_subscription: id_subs,
      active_claim: "0",
      status: "waiting-approval",
      start_subscription: currentDate.toISOString(),
      end_subscription: futureDate.toISOString(),
      created_at: new Date().toISOString(),
      snap_token: imageName,
    };

    await postData("/subscriptions", newSubscriptionObj);

    const formatDateTime = (date) =>
      `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(date.getDate()).padStart(2, "0")} ${String(
        date.getHours()
      ).padStart(2, "0")}:${String(date.getMinutes()).padStart(
        2,
        "0"
      )}:${String(date.getSeconds()).padStart(2, "0")}`;

    await postData("/transactions", {
      id_transaction: crypto.randomUUID(),
      id_subscription: id_subs,
      transaction_status: "waiting-approval",
      gross_amount: parseInt(selectedPackage.price),
      payment_type: "manual",
      transaction_time: formatDateTime(new Date()),
    });

    toast.success("Success adding new packages", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    });

    setSelectedPackage("");
    setFormPackage([
      {
        username: user?.username,
        phone_num: user?.phone_num,
        email: user?.email,
      },
    ]);
    closeModalManualJoin();
    closeModal();
  }

  const query = new URLSearchParams(useLocation().search);
  const paramValue = query.get("id_product");

  useEffect(() => {
    if (paramValue) {
      setSelectedIdProduct(paramValue);
    }
  }, []);

  const numberToArr = (pax) => {
    const array = [];
    for (let index = 0; index < pax; index++) {
      array.push(index + 1);
    }

    return array;
  };

  const openModalManualJoin = () => {
    setShowManualJoin(true);
  };

  const closeModalManualJoin = () => {
    setShowManualJoin(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  function formatNumber(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  const load = async () => {
    try {
      setLoading(true);
      const response = await fetchData(`/packages`);

      setPackages(response ? response : []);

      const responseProduct = await fetchData(`/products`);

      setProducts(
        responseProduct
          ? responseProduct.filter((item) => item.isProductActive === "true")
          : []
      );

      setLoading(false);
    } catch (error) {
      // Handle errors here
      console.error("Error:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    load();
  }, []);

  if (loading) {
    return (
      <span className="loading loading-spinner w-[70px] mx-auto flex justify-center min-h-screen"></span>
    );
  }

  const joinPackage = async (item) => {
    if (localStorage.getItem("token")) {
      let currentUser = JSON.parse(localStorage.getItem("user"));
      let picUsername;
      let id_subs = crypto.randomUUID();

      if (item.pax > 1) {
        if (item.pax != formPackage.length) {
          openModal();
          return;
        }
      }

      openModalManualJoin();

      // midtrans way
      // const req = await postData("/midtrans/snap-token", {
      //   params: {
      //     transaction_details: {
      //       order_id: id_subs,
      //       gross_amount: parseInt(item.price),
      //     },
      //     callbacks: {
      //       finish: "https://gaiastudio.id/finish-payment",
      //     },
      //     customer_details: {
      //       first_name: user.name,
      //       last_name: "",
      //       email: user.username,
      //       phone: user.phone_num,
      //     },
      //   },
      // });

      // console.log(req?.token, "req?.token");

      // window.snap.pay(req?.token, {
      //   onSuccess: async function (result) {
      //     /* You may add your own implementation here */
      //     if (item.pax > 1) {
      //       if (item.pax == formPackage.length) {
      //         picUsername = formPackage[0];
      //         await postData("/subscriptions/groups/user", {
      //           groups: formPackage,
      //           id_subscription: id_subs,
      //         });
      //       }
      //     }

      //     let currentDate = new Date(); // Current date
      //     let futureDate = new Date();
      //     futureDate.setDate(futureDate.getDate() + item.valid_days);

      //     let newSubscriptionObj = {
      //       id_packages: item.id_packages,
      //       username: picUsername ? picUsername.username : currentUser.username,
      //       id_subscription: id_subs,
      //       active_claim: "0",
      //       status: "active",
      //       start_subscription: currentDate.toISOString(),
      //       end_subscription: futureDate.toISOString(),
      //       created_at: new Date().toISOString(),
      //       snap_token: req?.token,
      //     };

      //     await postData("/subscriptions", newSubscriptionObj);

      //     toast.success("Success adding new packages", {
      //       position: "top-right",
      //       autoClose: 5000,
      //       hideProgressBar: false,
      //       closeOnClick: true,
      //       pauseOnHover: true,
      //     });

      //     setSelectedPackage("");
      //     setFormPackage([
      //       {
      //         username: user?.username,
      //         phone_num: user?.phone_num,
      //         email: user?.email,
      //       },
      //     ]);
      //     closeModal();
      //   },
      //   onPending: async function (result) {
      //     /* You may add your own implementation here */
      //     if (item.pax > 1) {
      //       if (item.pax == formPackage.length) {
      //         picUsername = formPackage[0];
      //         await postData("/subscriptions/groups/user", {
      //           groups: formPackage,
      //           id_subscription: id_subs,
      //         });
      //       }
      //     }

      //     let currentDate = new Date(); // Current date
      //     let futureDate = new Date();
      //     futureDate.setDate(futureDate.getDate() + item.valid_days);

      //     let newSubscriptionObj = {
      //       id_packages: item.id_packages,
      //       username: picUsername ? picUsername.username : currentUser.username,
      //       id_subscription: id_subs,
      //       active_claim: "0",
      //       status: "not-active",
      //       start_subscription: currentDate.toISOString(),
      //       end_subscription: futureDate.toISOString(),
      //       created_at: new Date().toISOString(),
      //       snap_token: req?.token,
      //     };

      //     await postData("/subscriptions", newSubscriptionObj);

      //     toast.warn("Your package is pending", {
      //       position: "top-right",
      //       autoClose: 5000,
      //       hideProgressBar: false,
      //       closeOnClick: true,
      //       pauseOnHover: true,
      //     });

      //     setSelectedPackage("");
      //     setFormPackage([
      //       {
      //         username: user?.username,
      //         phone_num: user?.phone_num,
      //         email: user?.email,
      //       },
      //     ]);
      //     closeModal();
      //   },
      //   onError: function (result) {
      //     /* You may add your own implementation here */
      //     alert("payment failed!");
      //     console.log(result);
      //   },
      //   onClose: function () {
      //     /* You may add your own implementation here */
      //     // alert("you closed the popup without finishing the payment");
      //     setSelectedPackage("");
      //     setFormPackage([
      //       {
      //         username: user?.username,
      //         phone_num: user?.phone_num,
      //         email: user?.email,
      //       },
      //     ]);
      //   },
      // });
    } else {
      dispatch(
        openModalLogin({
          title: "",
          size: "lg",
          bodyType: MODAL_BODY_TYPES.SIGN_IN_MODAL,
          extraObject: { isSignIn: true },
        })
      );

      localStorage.setItem("redirectPath", location.pathname);
    }
  };

  return (
    <>
      {showModal && (
        <dialog
          id="my_modal_5"
          className="modal modal-bottom sm:modal-middle modal-open"
        >
          <form method="dialog" className="modal-backdrop">
            <button onClick={closeModal}>close</button>
          </form>
          <div className="modal-box">
            <h3 className="font-bold text-lg">Group Subscription</h3>
            <div>
              {numberToArr(selectedPackage?.pax).map((person, i) => {
                return (
                  <div className="my-4 flex flex-col gap-3">
                    <div className="mb-3">Person {person}</div>
                    <label className="input input-bordered flex items-center gap-2">
                      <InboxIcon className="w-5" />
                      <input
                        type="text"
                        className="grow"
                        placeholder="Email"
                        defaultValue={i === 0 ? user?.username : ""}
                        disabled={i === 0}
                        onChange={(e) => {
                          const form = formPackage;
                          form[i] = {
                            ...form[i],
                            email: e.target.value,
                          };
                          setFormPackage(form);
                        }}
                      />
                    </label>
                    <label className="input input-bordered flex items-center gap-2">
                      <UserIcon className="w-5" />
                      <input
                        type="text"
                        className="grow"
                        placeholder=""
                        defaultValue={i === 0 ? user?.name : ""}
                        disabled={i === 0}
                        onChange={(e) => {
                          const form = formPackage;
                          form[i] = {
                            ...form[i],
                            name: e.target.value,
                          };
                          setFormPackage(form);
                        }}
                      />
                    </label>
                    <label className="input input-bordered flex items-center gap-2">
                      <PhoneIcon className="w-5" />
                      <input
                        type="text"
                        className="grow"
                        defaultValue={i === 0 ? user?.phone_num : ""}
                        disabled={i === 0}
                        onChange={(e) => {
                          const form = formPackage;
                          form[i] = {
                            ...form[i],
                            phone_num: e.target.value,
                          };
                          setFormPackage(form);
                        }}
                      />
                    </label>
                  </div>
                );
              })}
            </div>
            <div className="modal-action">
              <form method="dialog">
                {/* if there is a button in form, it will close the modal */}
                <button className="btn" onClick={closeModal}>
                  Close
                </button>
              </form>
              <button
                className="btn btn-primary"
                onClick={() => {
                  joinPackage(selectedPackage);
                }}
              >
                Save
              </button>
            </div>
          </div>
        </dialog>
      )}

      {showManualJoin && (
        <dialog id="my_modal_6" className="modal modal-middle modal-open">
          <form method="dialog" className="modal-backdrop">
            <button onClick={closeModal}>close</button>
          </form>
          <div className="modal-box w-11/12 max-w-5xl">
            <h3 className="font-bold text-lg">Pembayaran</h3>
            <div className="grid grid-cols-2 gap-5 max-md:grid-cols-1">
              <div>
                <div className="flex justify-center items-center flex-col my-10">
                  <div className="font-bold text-2xl">John Doe</div>
                  <div className="font-semibold text-xl">
                    123456789 (Bank ABC)
                  </div>
                </div>
                <div>
                  <div className=" font-semibold">Instruksi Pembayaran:</div>

                  <ul className="list-disc pl-5">
                    <li>
                      Silakan transfer sejumlah nominal yang telah ditentukan ke
                      rekening diatas
                    </li>
                    <li>
                      Setelah melakukan transfer, harap ambil screenshot atau
                      foto bukti pembayaran.
                    </li>
                    <li>
                      Unggah bukti pembayaran Anda melalui formulir yang
                      tersedia di bawah ini.
                    </li>
                  </ul>

                  <div className="mt-5 font-semibold">Catatan Penting:</div>

                  <ul className="list-disc pl-5">
                    <li>
                      Pastikan jumlah transfer sesuai dengan yang tertera untuk
                      mempermudah verifikasi.
                    </li>
                    <li>
                      Bukti bayar wajib diunggah dalam format JPG, PNG, atau PDF
                      dan ukuran file maksimal 2MB.
                    </li>
                    <li>
                      Apabila terjadi kesalahan atau membutuhkan bantuan,
                      silakan hubungi kami melalui WhatsApp di +62
                      813-5099-4499.
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <div className="w-full max-w-lg mx-auto h-[90%] ">
                  {/* Drag-and-drop area */}
                  <div
                    className="relative flex flex-col items-center justify-center w-full h-full p-6 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer bg-gray-100 hover:bg-gray-50"
                    onDrop={handleDrop}
                    onDragOver={preventDefaults}
                    onDragEnter={preventDefaults}
                    onDragLeave={preventDefaults}
                  >
                    <input
                      type="file"
                      className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                      onChange={(e) => handleFile(e.target.files[0])} // Take only the first file
                      multiple
                    />
                    <div className="flex flex-col items-center space-y-2">
                      <svg
                        className="w-10 h-10 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M3 15a4 4 0 000 8h6m-3-8a4 4 0 014-4h5m0-4a4 4 0 014 4h1.8a4 4 0 014 4v6.7"
                        />
                      </svg>
                      <p className="text-sm text-gray-600">
                        Drag and drop your files here, or{" "}
                        <span className="text-blue-500 underline">browse</span>.
                      </p>
                      <p className="text-xs text-gray-400">
                        Supported formats: PDF, JPG, PNG
                      </p>
                    </div>
                  </div>

                  {file && (
                    <div className="mt-4 flex items-center justify-between p-2 bg-white rounded-md shadow-sm">
                      <span className="truncate">{file.name}</span>
                      <button
                        onClick={handleRemove}
                        className="text-red-500 hover:underline text-sm"
                      >
                        Remove
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-action">
              <form method="dialog">
                {/* if there is a button in form, it will close the modal */}
                <button className="btn" onClick={closeModalManualJoin}>
                  Close
                </button>
              </form>

              <button
                className="btn btn-primary"
                onClick={handleSavePembayaranManual}
              >
                Save
              </button>
            </div>
          </div>
        </dialog>
      )}

      <div className="grid place-items-center w-full bg-base-100 pt-6 pb-48 ">
        <div className="flex gap-3 justify-center items-center max-sm:justify-start overflow-x-scroll  mx-2 max-sm:max-w-[80%]">
          <button
            className={`btn  ${
              "semua" == selectedIdProduct ? "btn-primary" : "btn-ghost"
            } rounded-full min-w-20`}
            onClick={() => setSelectedIdProduct("semua")}
          >
            All
          </button>

          {products.map((product) => {
            return (
              <button
                key={product.id_product}
                role="tab"
                className={`btn  ${
                  product.id_product === selectedIdProduct
                    ? "btn-primary"
                    : "btn-ghost"
                } rounded-full min-w-20`}
                onClick={() => setSelectedIdProduct(product.id_product)}
              >
                {product.name}
              </button>
            );
          })}
        </div>

        <div
          className={`gap-3 rounded-lg mt-10  ${
            loading ? "" : "grid grid-cols-3 max-sm:grid-cols-1 "
          }`}
        >
          {loading ? (
            <span className="loading loading-ring loading-lg m-auto my-10 flex justify-center"></span>
          ) : (
            <>
              {packages
                .filter((item) => {
                  if (selectedIdProduct == "semua") {
                    return true;
                  }

                  if (selectedIdProduct == item.id_product) {
                    return true;
                  }

                  return false;
                })
                .map((item, index) => {
                  return (
                    <div
                      className="card w-full bg-base-100 shadow-xl max-sm:m-5"
                      key={index}
                    >
                      <div className="card-body">
                        <h6 className="capitalize">
                          {
                            products.find(
                              (productItem) =>
                                productItem.id_product === item.id_product
                            )?.name
                          }{" "}
                          Package
                        </h6>
                        <h2 className="card-title min-h-14 max-h-14 line-clamp-2 mb-2 ">
                          {item.name}
                        </h2>
                        <p className="font-semibold my-5">
                          Rp. {formatNumber(item.price)}
                        </p>

                        <div className="rounded-xl bg-gray-100 p-5 flex flex-col gap-3">
                          <div className="flex items-center gap-2">
                            <CheckBadgeIcon className="w-5" />
                            <p className="font-semibold text-sm">
                              {item.times}x claim
                            </p>
                          </div>
                          <div className="flex items-start gap-2">
                            <ClockIcon className="w-6" />
                            <p className="font-semibold text-sm">
                              Valid for {item.valid_days} days from date of
                              purchase
                            </p>
                          </div>
                        </div>
                        <div className="card-actions mt-5">
                          <button
                            className="btn btn-primary w-full"
                            onClick={() => {
                              setSelectedPackage(item);
                              joinPackage(item);
                            }}
                            // disabled
                          >
                            Buy Package
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default PackagesPage;
