import React, { useState } from "react";
import ArchiveBoxIcon from "@heroicons/react/24/outline/ArchiveBoxIcon";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { postData } from "../utils/init";
import { useNavigate } from "react-router-dom";

function ListView({ data, actionLoadDate, selectedSubscription }) {
  const [showCancelled, setShowCancelled] = useState(false);
  const navigate = useNavigate();

  if (data.length === 0) {
    return (
      <div className="flex justify-center items-center flex-col border w-full min-h-full">
        <div>
          <ArchiveBoxIcon className="w-16" />
        </div>
        <div className="text-xl font-semibold text-center">
          You havent claim any session yet with this package
        </div>
        <button
          className="btn btn-primary mt-5"
          onClick={() => {
            navigate("/schedules");
          }}
        >
          See schedule
        </button>
      </div>
    );
  }

  return (
    <div className="border">
      <div className="w-full flex gap-5 items-center justify-between px-2">
        <div className="flex items-center gap-1">
          <input
            type="checkbox"
            defaultChecked={showCancelled}
            className="checkbox checkbox-xs"
            onChange={() => {
              setShowCancelled(!showCancelled);
            }}
          />
          Show canceled session
        </div>
        <button
          className="btn btn-link"
          onClick={() => {
            navigate("/schedules");
          }}
        >
          See schedule with this session
        </button>
      </div>

      {data.map((item) => {
        const filteredData = item.schedules.filter((filteritem) => {
          if (!showCancelled) {
            if (filteritem.active_session_status !== "active") {
              return false;
            }
          }

          return true;
        });

        return (
          <>
            {filteredData.length ? (
              <div className="font-bold flex justify-between items-center bg-primary p-3 text-white">
                <div>{item.day}</div>
                <div>{dayjs(item.date).format("MMMM DD, YYYY")}</div>
              </div>
            ) : (
              <></>
            )}

            {filteredData.map((schedule) => {
              const date = dayjs(schedule.date);
              const endDate = date.add(schedule.duration, "minute");
              const today = dayjs(); // Get today's date
              const now = dayjs();
              // Hitung selisih waktu antara sekarang dan jadwal kelas
              const diffInHours = date.diff(now, "hour");

              return (
                <div
                  className="collapse bg-white rounded-none"
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.07) 0px 4px 16px",
                  }}
                >
                  <input type="checkbox" />
                  <div
                    className={`collapse-title  w-full flex items-center ${
                      date.isBefore(today, "day") ? "bg-gray-200" : ""
                    }`}
                  >
                    <div className="p-3 min-w-[20%]">
                      {date.format("HH:mm")} - {endDate.format("HH:mm")}
                    </div>
                    <div className="p-3 w-full flex justify-between items-center">
                      <div>
                        {schedule.nama_sesi} - {schedule.difficulty}
                      </div>
                      <div>
                        {schedule.active_session_status === "active" &&
                        !date.isBefore(today, "day") &&
                        diffInHours > 12 ? (
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={async () => {
                              // cek apakah masih 12 jam sebelum mulai

                              // Cek apakah selisih waktu kurang dari 12 jam
                              if (diffInHours < 12) {
                                toast.error(
                                  "Reschedule maximal 12 jam sebelum kelas dimulai",
                                  {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                  }
                                );

                                return;
                              }

                              if (
                                selectedSubscription?.subscription_group_ids
                              ) {
                                const res = await postData(
                                  "/cancel-session-group",
                                  {
                                    id_session: schedule.id_session,
                                    id_schedule: schedule.id_schedule,
                                    id_subscription: schedule.id_subscription,
                                    status: schedule.active_session_status,
                                    groupLength:
                                      selectedSubscription.subscription_group_ids.split(
                                        ","
                                      ).length,
                                  }
                                );
                              } else {
                                const res = await postData("/cancel-session", {
                                  id_attendance: schedule.id_attendance,
                                  id_member: schedule.username,
                                  id_session: schedule.id_session,
                                  id_schedule: schedule.id_schedule,
                                  id_subscription: schedule.id_subscription,
                                  status: schedule.active_session_status,
                                });
                              }

                              toast.success("Success Canceling", {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                              });

                              actionLoadDate();
                            }}
                          >
                            Cancel
                          </button>
                        ) : (
                          <></>
                        )}

                        {schedule.active_session_status === "cancel" ? (
                          <div className="badge badge-error gap-2 text-white">
                            Canceled
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="collapse-content">
                    <div className="flex w-full max-sm:flex-col max-sm:gap-5">
                      <div className="w-[30%] max-sm:w-full font-bold">
                        Terms And Condition
                      </div>
                      <div>
                        <ul>
                          {schedule.termscondition?.split(",")?.map((item) => {
                            if (item) {
                              return (
                                <li className="text-lg font-base">- {item}</li>
                              );
                            }
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        );
      })}
    </div>
  );
}

export default ListView;
