/* eslint-disable jsx-a11y/anchor-is-valid */
import dayjs from "dayjs";
import { fetchData, postData } from "../utils/init";
import React, { useState, useEffect } from "react";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import LockOpenIcon from "@heroicons/react/24/outline/LockOpenIcon";
import ClipboardDocumentListIcon from "@heroicons/react/24/outline/ClipboardDocumentListIcon";
import UserCircleIcon from "@heroicons/react/24/outline/UserCircleIcon";
import { toast } from "react-toastify";

// Extend Day.js with UTC and timezone plugins
dayjs.extend(utc);
dayjs.extend(timezone);

const ProfileSection = () => {
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
  const [name, setname] = useState(user.name);
  const [phone, setphone] = useState(user.phone_num);

  return (
    <div className="flex flex-col gap-5">
      <div>
        <div>Email</div>
        <input
          type="text"
          placeholder="Name"
          className="input input-bordered w-full max-w-xs"
          defaultValue={user.username}
          disabled
        />
      </div>

      <div>
        <div>Name</div>
        <input
          type="text"
          placeholder="Name"
          className="input input-bordered w-full max-w-xs"
          defaultValue={name}
          onChange={(e) => {
            setname(e.target.value);
          }}
        />
      </div>

      <div>
        <div>Phone Number</div>
        <input
          type="text"
          placeholder="Phone Number"
          className="input input-bordered w-full max-w-xs"
          defaultValue={phone}
          onChange={(e) => {
            setphone(e.target.value);
          }}
        />
      </div>

      <button
        className="btn btn-primary max-w-fit max-sm:min-w-full mx-auto mt-10"
        onClick={async () => {
          const res = await postData("/auth/members/change-bio", {
            username: user.username,
            name: name,
            phone_num: phone,
          });

          if (res) {
            toast.success("Success Edit Bio", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });

            localStorage.setItem("user", JSON.stringify(res));
          }
        }}
      >
        Save Changes
      </button>
    </div>
  );
};

const ChangePasswordSection = () => {
  const [newConfirmPass, setnewConfirmPass] = useState("");
  const [newPass, setnewPass] = useState("");
  const [old, setold] = useState("");
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;

  return (
    <div className="flex flex-col gap-5">
      <div>
        <div>Old Password</div>
        <input
          type="password"
          placeholder="old password"
          className="input input-bordered w-full max-w-xs"
          defaultValue={old}
          onChange={(e) => {
            setold(e.target.value);
          }}
        />
      </div>
      <div>
        <div>New Password</div>
        <input
          type="password"
          placeholder="new password"
          className="input input-bordered w-full max-w-xs"
          defaultValue={newPass}
          onChange={(e) => {
            setnewPass(e.target.value);
          }}
        />
      </div>
      <div>
        <div>Confirm New Password</div>
        <input
          type="password"
          placeholder="confirmation new password"
          className="input input-bordered w-full max-w-xs"
          defaultValue={newConfirmPass}
          onChange={(e) => {
            setnewConfirmPass(e.target.value);
          }}
        />
      </div>

      <button
        className="btn btn-primary max-w-fit max-sm:min-w-full mx-auto mt-10"
        onClick={async () => {
          if (newPass == newConfirmPass) {
            const res = await postData("/auth/members/change-password", {
              password: newPass,
              current_password: old,
              username: user.username,
            });
            console.clear();
            console.log(res.data);

            if (res?.data?.error) {
              toast.error(res?.data?.error, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
              });
            } else {
              toast.success("Success change password", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
              });
            }
          } else {
            toast.error("Confirm password is not the same as new password", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }}
      >
        Save Changes
      </button>
    </div>
  );
};

const MySubscriptionSection = () => {
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;

  const [subscriptions, setSubscriptions] = useState([]);
  const [LoadingHistory, setLoadingHistory] = useState(false);
  const [history, setHistory] = useState([]);

  const load = async () => {
    const data = await fetchData(`/subscriptions/all-member/${user.username}`);
    setSubscriptions(data ? data : []);
  };

  const loadSubscription = async (id) => {
    try {
      setLoadingHistory(true);

      const response = await fetchData(`/active-session/by-subscription/${id}`);

      setHistory(response ? response : []);
      setLoadingHistory(false);
    } catch (error) {
      // Handle errors here
      console.error("Error:", error);
      setLoadingHistory(false);
    }
  };

  const getTxStatus = (index) => {
    if (index === "settlement" || index === "capture")
      return <div className="badge p-3 text-white badge-success">{index}</div>;
    else if (index === "pending")
      return <div className="badge p-3 text-white badge-warning">{index}</div>;
    else
      return <div className="badge p-3 badge-error text-white ">{index}</div>;
  };

  const getStatus = (index) => {
    if (index === "active")
      return <div className="badge p-3 text-white badge-success">Active</div>;
    else
      return (
        <div className="badge p-3 badge-error text-white ">Not Active</div>
      );
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <div className="flex flex-col gap-5">
      {subscriptions.map((item, i) => {
        const formattedDate = dayjs
          .utc(item.subs_created_at)
          .tz("Asia/Jakarta")
          .format("YYYY-MM-DD HH:mm");

        return (
          <div
            key={i}
            className="card bg-white shadow-xl text-primary-content w-full"
          >
            <div className="card-body relative">
              {/* <div>
                {item.active_claim} / {item.times}
              </div> */}
              <h2 className="card-title">
                {item.nama_paket} {item.times} Session
              </h2>
              <p>{item.nama_produk}</p>
              <div className="flex justify-between items-center max-sm:flex-col gap-5">
                <div className="text-sm font-semibold absolute top-3 right-5">{`${dayjs(
                  new Date(item.start_subscription)
                ).format("DD MMM YY")} - ${dayjs(
                  new Date(item.end_subscription)
                ).format("DD MMM YY")}`}</div>
                <div className="max-sm:mt-5">
                  {/* {dayjs(item.subs_created_at).format("DD MMMM YYYY HH:mm")} */}
                  {formattedDate}
                </div>
                <div className="flex gap-3 items-center">
                  {/* <button className="btn">Buy Now</button>
                   */}
                  <div className={"capitalize"}>
                    <div className="text-xs mb-1">Payment</div>
                    <div>{getTxStatus(item.transaction_status)}</div>
                  </div>
                  <div className={""}>
                    <div className="text-xs mb-1">Status</div>
                    <div>{getStatus(item.subscription_status)}</div>
                  </div>

                  {item.transaction_status === "pending" ? (
                    <button
                      className="btn btn-primary  text-white mr-2"
                      onClick={() => {
                        window.snap.pay(item.snap_token, {
                          onSuccess: async function (result) {},
                          onPending: async function (result) {},
                          onError: function (result) {},
                          onClose: function () {},
                        });
                      }}
                    >
                      Pay
                    </button>
                  ) : (
                    <>
                      {item.transaction_status === "settlement" ||
                      item.transaction_status === "capture" ? (
                        // <button
                        //   className="btn btn-primary btn-sm text-white"
                        //   onClick={() => {
                        //     // const url =
                        //     //   "https://api.sandbox.midtrans.com/v2/order_id/refund";
                        //     // const options = {
                        //     //   method: "POST",
                        //     //   headers: {
                        //     //     accept: "application/json",
                        //     //     "content-type": "application/json",
                        //     //   },
                        //     //   body: JSON.stringify({
                        //     //     refund_key: "reference1",
                        //     //     amount: 5000,
                        //     //     reason: "for some reason",
                        //     //   }),
                        //     // };
                        //     // fetch(url, options)
                        //     //   .then((res) => res.json())
                        //     //   .then((json) => console.log(json))
                        //     //   .catch((err) => console.error("error:" + err));
                        //   }}
                        // >
                        //   Refund
                        // </button>
                        <></>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className="collapse collapse-arrow">
                <input
                  type="radio"
                  name="my-accordion-profile"
                  onClick={() => {
                    loadSubscription(item.id_subscription);
                  }}
                />
                <div className="collapse-title bg-base-100 font-semibold">
                  View All Session On This subscription
                </div>
                <div className="collapse-content">
                  {LoadingHistory ? (
                    <span className="loading loading-spinner loading-md"></span>
                  ) : (
                    <>
                      {history.length ? (
                        <>
                          {history.map((itemHistory, i) => {
                            return (
                              <div className="flex gap-3 my-2">
                                <div>{i + 1}</div>
                                <div className="w-[20%]">
                                  {getStatus(itemHistory.active_session_status)}
                                </div>
                                <div>
                                  {itemHistory.nama_sesi} -{" "}
                                  {itemHistory.difficulty}
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <div className="font-semibold text-center">
                          No Claim Yet On this subscription
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

function Profile() {
  const [tab, setTab] = useState(3);

  return (
    <div className="m-24 flex min-h-[80vh] max-sm:m-5">
      <div className="max-sm:hidden max-sm:fixed max-sm:bottom-0 max-sm:right-0 max-sm:left-0 max-sm:max-w-md bg-white z-10 w-[20%] max-sm:w-full">
        <div className=" max-sm:carousel max-sm:flex ">
          <button
            className={`carousel-item whitespace-nowrap w-full pr-3 max-sm:p-5 max-sm:w-[200px] py-3 rounded-none border-0 ${
              tab == 3
                ? "border-r-4 border-primary"
                : "border-r border-gray-200"
            } `}
            onClick={() => {
              setTab(3);
            }}
          >
            My Subscriptions
          </button>
          <button
            className={`carousel-item whitespace-nowrap w-full pr-3 max-sm:p-5 max-sm:w-[200px] py-3 rounded-none border-0 ${
              tab == 0
                ? "border-r-4 border-primary"
                : "border-r border-gray-200"
            } `}
            onClick={() => {
              setTab(0);
            }}
          >
            Profile
          </button>
          <button
            className={`carousel-item whitespace-nowrap w-full pr-3 max-sm:p-5 max-sm:w-[200px] py-3 rounded-none border-0 ${
              tab == 1
                ? "border-r-4 border-primary"
                : "border-r border-gray-200"
            } `}
            onClick={() => {
              setTab(1);
            }}
          >
            Change Password
          </button>
        </div>
      </div>
      <div className="flex-1 p-10 max-sm:p-0 w-[70%]">
        {tab === 0 ? (
          <ProfileSection />
        ) : (
          <>
            {tab === 1 ? (
              <ChangePasswordSection />
            ) : (
              <>
                {tab === 2 ? (
                  <></>
                ) : (
                  <>{tab === 3 ? <MySubscriptionSection /> : <></>}</>
                )}
              </>
            )}
          </>
        )}
      </div>

      <div className="btm-nav md:hidden z-20">
        <button onClick={() => setTab(3)} className={tab == 3 ? "active" : ""}>
          <ClipboardDocumentListIcon className="w-5" />
          <span className="btm-nav-label text-sm">My Subscription</span>
        </button>
        <button onClick={() => setTab(0)} className={tab == 0 ? "active" : ""}>
          <UserCircleIcon className="w-5" />
          <span className="btm-nav-label text-sm">Profile</span>
        </button>
        <button onClick={() => setTab(1)} className={tab == 1 ? "active" : ""}>
          <LockOpenIcon className="w-5" />
          <span className="btm-nav-label text-sm">Change Password</span>
        </button>
      </div>
    </div>
  );
}

export default Profile;
