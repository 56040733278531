import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import CalendarView from "./../components/CalendarView";

import ChevronLeftIcon from "@heroicons/react/24/outline/ChevronLeftIcon";
import { fetchData } from "../utils/init";

function SessionSchedules() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [schedules, setSchedules] = useState([]);
  const [session, setSession] = useState([]);
  const [loading, setLoading] = useState(false);

  const load = async () => {
    try {
      setLoading(true);
      const responseSession = await fetchData(`/session/${id}`);

      setSession(responseSession);
      const response = await fetchData(`/schedules/by-session/${id}`);

      const hourlySchedules = Array.from({ length: 17 }, (_, index) => ({
        hour: index + 7,
        schedules: [],
      }));

      // Group schedules by hour
      response.forEach((schedule) => {
        const date = new Date(schedule.date);
        const hour = date.getHours();

        if (hour >= 7 && hour <= 23) {
          hourlySchedules[hour - 7].schedules.push(schedule);
        }
      });

      // Output the result
      console.log(hourlySchedules);

      setSchedules(response);
      setLoading(false);
    } catch (error) {
      // Handle errors here
      console.error("Error:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    load();
  }, []);

  if (loading) {
    return (
      <span className="loading loading-spinner w-[70px] mx-auto flex justify-center min-h-screen"></span>
    );
  }

  return (
    <div>
      <div className="m-5 ">
        <div className="flex gap-2 items-center mb-3">
          <div>
            <button
              className="px-1 py-1 btn btn-square btn-sm"
              onClick={() => {
                navigate(`/sessions/${session.id_session}`);
              }}
            >
              <ChevronLeftIcon className="h-4 w-4" />
            </button>
          </div>
          <div className="text-sm breadcrumbs">
            <ul>
              <li>
                <a>Sessions</a>
              </li>
              <li>{session.name}</li>
              <li>Schedules</li>
            </ul>
          </div>
        </div>

        <div>
          <CalendarView
            schedules={schedules}
            sessions={[session]}
            setSchedules={setSchedules}
          />
        </div>
      </div>
    </div>
  );
}

export default SessionSchedules;
