import { openModal } from "../store/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { MODAL_BODY_TYPES } from "../utils/globalConstantUtil";
import { setLoggedIn } from "../store/userSlice";
import { postData } from "../utils/init";
import { Link } from "react-router-dom";

function NavProfileas() {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;

  const openLoginModal = () => {
    dispatch(
      openModal({
        title: "",
        size: "lg",
        bodyType: MODAL_BODY_TYPES.SIGN_IN_MODAL,
        extraObject: { isSignIn: true },
      })
    );
  };

  const openRegisterModal = () => {
    dispatch(
      openModal({
        title: "",
        size: "lg",
        bodyType: MODAL_BODY_TYPES.SIGN_UP_MODAL,
        extraObject: { isSignIn: true },
      })
    );
  };

  const openPricingModal = () => {
    console.log("jere");
    dispatch(
      openModal({
        title: "",
        size: "lg",
        bodyType: MODAL_BODY_TYPES.PRICING_MODAL,
        extraObject: { isLoggedIn: token ? true : false, darkbg: true },
      })
    );
  };

  const logoutUser = async () => {
    localStorage.clear();
    dispatch(setLoggedIn(false));
    await postData("/auth/logout", {});
    window.location = "/";
  };

  const handleDropDownClick = () => {
    const elem = document.activeElement;
    if (elem) {
      elem?.blur();
    }
  };

  return (
    <>
      {token ? (
        <>
          <div className="font-semibold text-md hidden lg:flex">
            {user?.name}
          </div>
          <div className="dropdown md:mt-0 mt-4 ml-6 dropdown-end hidden lg:flex">
            <label
              tabIndex={0}
              className="btn md:hidden btn-sm normal-case btn-outline m-1"
            >
              Account
            </label>

            <label
              tabIndex={0}
              className="btn bg-white md:flex hidden btn-circle  avatar"
            >
              <div className="w-6 rounded-full">
                <img
                  src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                  alt="profile"
                />
              </div>
            </label>
            <ul
              tabIndex={0}
              className="menu menu-compact z-20 dropdown-content bg-base-100 mt-3 p-2 shadow   rounded-box w-52"
            >
              <li
                className="justify-between"
                onClick={() => handleDropDownClick()}
              >
                <Link to="/profile">My Profile</Link>
              </li>

              <div className="divider mt-0 mb-0"></div>
              <li>
                <a onClick={logoutUser}>Logout</a>
              </li>
            </ul>
          </div>

          <div className="lg:hidden">
            <li className="mr-2">
              <Link to="/profile">My Profile</Link>
            </li>
            <li className="mr-2">
              <button onClick={logoutUser}>Logout</button>
            </li>
          </div>
        </>
      ) : (
        <div className="flex gap-4 max-sm:flex-col max-sm:mt-10">
          <button
            className="btn btn-sm text-xs normal-case btn-outline "
            onClick={() => openLoginModal()}
          >
            Login
          </button>
          <button
            onClick={() => openRegisterModal()}
            className="btn  btn-sm text-xs btn-primary "
          >
            Register
          </button>
        </div>
      )}
    </>
  );
}

export default NavProfileas;
